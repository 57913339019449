<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号">
                <a-input v-model="queryParam.order_no" placeholder="请输入编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="手机号">
                <a-input v-model="queryParam.cellphone" placeholder="请输入手机号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="状态">
                <a-select v-model="queryParam.status" allow-clear placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.applet_order_status">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24" v-if="false">
              <a-form-item label="自提优先">
                <a-checkbox v-model="queryParam.distribution" @change="onDeliverFirstChange"/>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
            <a-col :md="2" :sm="24" v-if="false">
              <a-button type="primary" @click="handle_to_warehouse">转到仓库</a-button>
            </a-col>
            <a-col :md="2" :sm="24" v-if="false">
              <a-button type="primary" @click="handleExportOrder">导出订单</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-alert :showIcon="true" style="margin-bottom: 16px" v-if="false">
        <template slot="message">
          <span style="margin-right: 12px">已选择: <a style="font-weight: 600">{{ this.selectedRowKeys.length }}</a></span>
          <a style="margin-left: 24px" @click="clear" :disabled="!hasSelected" v-if="hasSelected">清空</a>
        </template>
      </a-alert>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :pageNum="pageNum"
        :scroll="{ x: 1300 }"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="max-width: 150px; min-width: 110px;text-align: right">
          {{ (text/100).toFixed(2) }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleDetail(record)">详情</a>
            <a-divider type="vertical" v-if="showMore(record)"/>
            <a-dropdown v-if="showMore(record)">
              <a class="ant-dropdown-link">
                更多 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="showRefund(record)">
                  <a @click="handleRefund(record)">退款</a>
                </a-menu-item>
                <a-menu-item v-if="showApproval(record)">
                  <a @click="handleConfirmPayed(record)">审批</a>
                </a-menu-item>
                <a-menu-item v-if="showChangeStatus(record)">
                  <a @click="handleChangeStatus(record)">更改状态</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </span>
      </s-table>
      <a-modal
        v-model="select_status_visible"
        :confirm-loading="confirm_loading"
        title="审核"
        ok-text="确认"
        cancel-text="取消"
        @ok="select_status">
        <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
          <a-select-option
            v-for=" (val, key) in this.$Dictionaries.applet_order_exam_status"
            :key="key"
            :value="key">
            {{ val }}
          </a-select-option>
        </a-select>
        <br />
        <br />
        <!--  <span v-if="select_status_data === '5'">-->
        <!--  退款金额：<a-input-number :min="0" v-model="refund" style="width: 180px"/> 元</span>-->
      </a-modal>
      <a-modal v-model="refund_status_visible" title="退款" ok-text="确认" cancel-text="取消" @ok="select_refund">
        <a-row :gutter="2">
          <a-col :md="12" :sm="24">
            <a-form-item label="金额(元)">
              <a-input-number :min="0" v-model="refund" style="width: 140px"/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="理由">
              <a-input v-model="refund_reason" placeholder="请输入理由"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-modal>
      <order-circulate-config
        ref="configModal"
        v-if="configVisible"
        :visible="configVisible"
        :loading="confirmLoading"
        :model="mdl"
        :title="title"
        @cancel="handleConfigCancel"
        @ok="handleConfigOk"
      />
      <order-status-form
        ref="orderStatusModal"
        v-if="statusVisible"
        :visible.sync="statusVisible"
        :loading="statusConfirmLoading"
        :model="mdl"
        @cancel="handleStatusCancel"
        @ok="handleStatusOk" />
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  doRefund,
  orderToExamine,
  orderList,
  putReceived,
  orderToWarehouse,
  putOrderStatus, putOrderToWarehouseConfig, orderToSupplier, wechatOrderExport
} from '@/api/applet_shopmall_order'
import OrderCirculateConfig from '@/views/a-applet/shop_manage/OrderCirculateConfig'
import Modal from 'ant-design-vue/es/modal'
import OrderStatusForm from '@/views/a-applet/shop_manage/OrderStatusForm'
export default {
  name: 'TableList',
  components: {
    STable,
    OrderStatusForm,
    OrderCirculateConfig
  },
  data () {
    return {
      title: '自动转到仓库订单',
      pageNum: 1,
      select_status_visible: false,
      confirm_loading: false,
      refund_status_visible: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      configVisible: false,
      statusVisible: false,
      statusConfirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {
        is_main_order: true
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          getCheckboxProps: record => ({
            props: {
              disabled: (record.status !== 2) || (record.is_sync) || (record.is_have_child)
            }
          }),
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单编号',
          ellipsis: true,
          dataIndex: 'order_no',
          fixed: 'left',
          width: 200
        },
        {
          title: '收货人',
          width: 120,
          dataIndex: 'receiving_name'
        },
        {
          title: '电话',
          ellipsis: true,
          width: 150,
          dataIndex: 'receiving_phone'
        },
        {
          title: '支付金额',
          dataIndex: 'actual_paid',
          align: 'right',
          width: 150,
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '支付方式',
          dataIndex: 'pay_method_code',
          align: 'center',
          width: 120,
          customRender: (text) => this.$Dictionaries.order_pay_method[text] || '无'
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          align: 'center',
          width: 120,
          customRender: (text) => this.$Dictionaries.applet_order_status[text] || '无'
        },
        {
          title: '订单来源',
          dataIndex: 'source',
          align: 'center',
          width: 150,
          customRender: (text) => this.$Dictionaries.applet_order_source[text] || '无'
        },
        {
          title: '是否有子订单',
          dataIndex: 'is_have_child',
          width: 130,
          align: 'center',
          customRender: (text) => text ? '有' : '无'
        },
        {
          title: '供应商',
          width: 250,
          align: 'left',
          ellipsis: true,
          dataIndex: 'supplier.name'
        },
        {
          title: '线下付款审批人',
          width: 150,
          align: 'center',
          ellipsis: true,
          dataIndex: 'approve_by',
          customRender: (text) => (text === '') ? '-' : text
        },
        {
          title: '下单时间',
          width: 200,
          align: 'center',
          dataIndex: 'create_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '支付时间',
          dataIndex: 'pay_time',
          width: 200,
          align: 'center',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 150,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            console.log('订单管理：', res)
            return res.data
          })
      }
    }
  },
  created () {
    // this.tableOption()
    this.setCurrentPage()
  },
  computed: {
    showMore () {
      return (record) => {
        return this.showApproval(record) || this.showRefund(record) || this.showChangeStatus(record)
      }
    },
    rowSelection () {
      const that = this
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          that.onSelectChange(selectedRowKeys, selectedRows)
        },
        selectedRowKeys: this.selectedRowKeys,
        getCheckboxProps: record => ({
          props: {
            disabled: (record.status !== 2) || (record.is_sync) || (record.is_have_child)
          }
        })
      }
    },
    hasSelected () {
      return this.selectedRowKeys.length > 0
    },
    showRefund () {
      // 显示“退款”按钮
      return (record) => {
        const statusFilter = (record.status !== 8 && record.status !== 7 && record.status !== 5 && record.status !== 12 && record.status !== 6 && record.status >= 2)
        const payMethodFilter = (record.pay_method_code !== 'off_pay' || (record.pay_method_code === 'off_pay' && record.cash_actual_paid === 0))
        return statusFilter && payMethodFilter
      }
    },
    showChangeStatus () {
      return (record) => {
        const firstFilter = (record.status === 1)
        return firstFilter
      }
    },
    showApproval () {
      return (record) => {
        return record.status === 12 && record.pay_method_code === 'off_pay'
      }
    }
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            getCheckboxProps: record => ({
              props: {
                disabled: (record.status !== 2) || (record.is_sync) || (record.is_have_child)
              }
            }),
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDetail (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      console.log(record.id)
      this.$router.push({ path: 'order/detail', query: { id: record.id, status: record.status, supplier: record.supplier.name } })
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.distribution = undefined
      } else {
        this.queryParam.distribution = 2
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    select_refund () {
      if (this.refund === 0) {
        this.$message.warning('请输入退款金额')
        return
      }
      const param = {
        refund: Math.round(this.refund * 100),
        reason: this.refund_reason,
        order_id: this.order_id
      }

      doRefund(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_warehouse () {
      if (this.selectedRows && this.selectedRows.length > 0) {
        const filterRows = this.selectedRows.filter(item => {
          return item.status === 2
        })
        if (filterRows.length !== this.selectedRows.length) {
          this.$message.warning('选择已支付未发货订单')
        } else {
          const data = {
            order_ids: this.selectedRowKeys,
            supplier_ids: this.selectedRows.map(item => item.supplier.id)
          }
          orderToWarehouse(data).then(res => {
            if (res.code === 1000) {
              this.$message.warning('操作成功')
              this.selectedRowKeys = []
              this.selectedRows = []
              this.$refs.table.refresh(true)
            }
          })
        }
      } else {
        this.$message.warning('选择订单')
      }
    },
    // 导出订单订单
    handleExportOrder () {
      if (this.selectedRows && this.selectedRows.length > 0) {
        const filterRows = this.selectedRows.filter(item => {
          return item.status === 2
        })
        if (filterRows.length !== this.selectedRows.length) {
          this.$message.warning('选择待发货订单')
        } else {
          const items = this.selectedRows.map(item => {
            return item.id + '@@' + item.supplier.id
          })
          const data = {
            orders: items.join(',')
          }
          if (data) {
            console.log(data)
          }
          wechatOrderExport(data).then(res => {
            console.log(res)
            const link = document.createElement('a')
            const blob = new Blob([res], { type: 'application/octet-stream' })
            console.log(blob)
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', `订单.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.selectedRowKeys = []
            this.selectedRows = []
          })
        }
      } else {
        this.$message.warning('选择订单')
      }
    },
    // 订单退款
    handleRefund (record) {
      // this.refund_status_visible = true
      // // 2 审核通过，5 审核不通过
      // this.order_id = record.id
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$router.push({ path: 'order/detail_refund', query: { id: record.id } })
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: 'order/detail_refund', query: { id: record.id, status: record.status } })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    handleReceived (value) {
      putOrderStatus(4, value.goods_type, value.id).then(data => {
        console.log(data)
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    handleConfirmPayed (value) {
      const that = this
      Modal.confirm({
        title: '审批',
        content: '该订单已经线下付款了吗？',
        okText: '是',
        cancelText: '否',
        onOk () {
          putOrderStatus(undefined, value.goods_type, value.id).then(data => {
            console.log(data)
            if (data) {
              if (data.code === 1000) {
                that.$refs.table.refresh(true)
              } else {
                if (data.msg) {
                  that.$message.warning(data.msg)
                }
              }
            }
          })
        }
      })
    },
    handlePushOrder (record) {
      const data = {
        order_id: record.id,
        supplier_id: record.supplier.id
      }
      orderToSupplier({ order: data }).then(res => {
        if (res.code === 1000) {
          this.$message.warning('操作成功')
          this.$refs.table.refresh(true)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    handleAgreeRefund (value) {
      putReceived({ order_no: value.order_no }).then(data => {
        console.log(data)
        if (data) {
            if (data.code === 1000) {
              this.$refs.table.refresh(true)
            } else {
              if (data.msg) {
                this.$message.warning(data.msg)
              }
            }
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleConfigCancel () {
      this.configVisible = false
      this.confirmLoading = false
    },
    handleConfigOk () {
      const form = this.$refs.configModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          putOrderToWarehouseConfig(values, this.mdl.id).then(res => {
            if (res.code === 1000) {
              this.handleConfigCancel()
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    clear () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    handleChangeStatus (record) {
      console.log('修改订单状态', record)
      this.mdl = record
      this.statusVisible = true
    },
    handleStatusCancel () {
      this.statusVisible = false
    },
    handleStatusOk () {
      console.log('')
      const form = this.$refs.orderStatusModal.form
      this.statusConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          putOrderStatus(values['status'], this.mdl.goods_type, this.mdl.id).then(res => {
            console.log(res)
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.statusVisible = false
            this.statusConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.statusConfirmLoading = false
        }
      })
    }
  }
}
</script>
